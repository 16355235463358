
   .video-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Adjusted */
    margin-top: 100px;
    height: 100%; /* Added */
  }
  
  .video-controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
  
  .control-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 36px;
    color: #000000;
  }
  
  .video-player {
    max-width: 60%;
    width: 100%;
    height: auto;
  }
  
  @media screen and (max-width: 768px) {
    .video-player {
      max-width: 90%;
    }
  
    .control-button {
      font-size: 24px;
    }
  }
  