.StudyCap-container {
    max-width: 80%; /* Adjust the maximum width as needed */
    margin: 0 auto; /* Center the content horizontally */
    padding: 20px; /* Add padding for better readability */
  }
  
  .StudyCap-container h1 {
    font-size: 2rem; /* Adjust the font size of the heading */
  }
  
  .StudyCap-container p {
    font-size: 1rem; /* Adjust the font size of paragraphs */
    line-height: 1.5; /* Set line height for better readability */
  }
  
  .vision-container {
    max-width: 80%;
    margin: 0 auto; /* Center the container horizontally */
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap to the next line */
    justify-content: center; /* Align flex items horizontally at the center */
}

.half {
  width: 50%;
  padding: 20px;
  box-sizing: border-box; /* Include padding in the width calculation */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content vertically at the center */
  align-items: center; /* Align content horizontally at the center */
}

/* Media query for responsiveness */
@media only screen and (max-width: 600px) {
  .StudyCap-container {
      padding: 10px; /* Adjust padding for smaller screens */
  }

  .StudyCap-container h1 {
      font-size: 1.5rem; /* Decrease font size for smaller screens */
  }

  .StudyCap-container p {
      font-size: 0.9rem; /* Decrease font size for smaller screens */
  }

  .half {
      width: 100%; /* Change width to 100% for smaller screens */
  }
}

@media only screen and (min-width: 1024px) {
  .half {
      margin-top: 50px; /* Add margin top for large devices */
  }
}
