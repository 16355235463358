

.logo {
    position: absolute;
    top: 50px;
    left: 20px;
  }
  
  .logo-image {
    width: 150px;
    height: auto;
  }
  
  .navbar {
    position: absolute;
    top: 50px;
    right: 20px;
    
  }
  
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  
  .menu.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    padding: 10px;
    border-radius: 5px;
  }
  
  .menu.open li {
    margin-bottom: 10px;
  }
  
  .menu.open a {
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
  
  .menu-toggle {
    position: absolute;
    top: 7px;
    right: 20px;
    cursor: pointer;
  }
  
  .menu-toggle .hamburger {
    width: 26px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
  }
  
  .open .hamburger:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .open .hamburger:nth-child(2) {
    opacity: 0;
  }
  
  .open .hamburger:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  .menu li {
    color: white;
    margin-left: 30px;
    font-size: 18px;
    cursor: pointer;
    position: relative;
  }
  
  .menu li::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: white;
    transition: width 0.3s ease;
  }
  
  .menu li:hover::after {
    width: 100%;
  }
  
  .menu li:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
  
  .menu li.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: white;
    transition: width 0.3s ease;
}

  
  
  @media (min-width: 769px) {
    .menu-toggle {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .menu {
      display: none;
      position: absolute;
      top: 60px;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 10px;
      border-radius: 5px;
      right: 20px;
    }
  
    .menu.open {
      margin-top: 70px;
      margin-right: 18px;
      display: flex;
      flex-direction: column;
      width: 180px;
      z-index: 2;
    }
  
    .menu li {
      margin-bottom: 10px;
    }
  
    .menu-toggle {
      display: block;
      margin-top: 30px;
    }
  
    .navbar {
      top: 20px;
      right: 20px;
      left: unset;
    }
  }
  