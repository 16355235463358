/* FormBox.css */
.form-box {
    display: flex;
    flex-direction: row; 
    width: 100%; 
    justify-content: space-between; 
    align-items: stretch; 
  }
  
  .form-box > :first-child {
    flex-basis: 50%; 
    margin-right: 10px;
  }
  
  .form-box > :last-child {
    flex-basis: 50%; 
    margin-left: 10px;
  }
  

  @media (max-width: 768px) {
    .form-box {
      flex-direction: column;
    }
  }
  