.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 80%;
  margin: 0 auto;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-item {
  flex-basis: calc(33.33% - 20px);
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  display: flex;
  align-items: center; /* Center items vertically */
}

.service-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 48px;
  height: 48px;
  margin-right: 15px; /* Adjust icon spacing */
  display: flex;
  justify-content: center; /* Center icon horizontally */
  align-items: center; /* Center icon vertically */
  border-radius: 50%; /* Make icon container circular */
  background-color: orange; /* Change icon background color */
}

.icon i {
  font-size: 24px;
  color: #ffffff; /* Change icon color */
}

.service-content {
  flex-grow: 1; /* Allow content to expand */
}

.service-item h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #333333;
}

.service-item p {
  margin-bottom: 0;
  color: #666666;
}
