/* Base styles */
.courses-container {
    margin: 20px;
  }
  
  .courses-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .course {
    background-color: #ffffff;
    border: 1px solid #eaeaea;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .course:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Header styles */
  .course-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .icon {
    margin-right: 12px;
    font-size: 20px;
  }
  
  .course h3 {
    margin: 0;
    font-size: 18px;
    color: #333333;
  }
  
  /* Description styles */
  .course p {
    margin-bottom: 12px;
    color: #666666;
  }
  
  /* Button styles */
  .course-btn {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .course-btn:hover {
    background-color: #45a049;
  }
  