.relative {
  position: relative;
 }
 .absolute {
  position: absolute;
 }
 
 .banner-container {
  display: inline-block;
  position: relative;
 }
 
 .banner-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transition: opacity 1s ease; 
 }
 
 .text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  width: 100%;
 }
 
 .button{
  position: absolute;
  top: 70%;
  right: 100px;
 }
 
 .explore-button{
  width: 250px;
  height: 70px;
  background-color: #0056b3;
  font-size:18px;
 }
 
 .explore-button:hover {
  background-color: #004080; /* Darker shade of blue on hover */
 }
 
 @media screen and (max-width: 768px) {
  .banner-image {
     height: 75vh;
  }
   
  .button{
     position: absolute;
     top: 70%;
     left: 80px;
  }
  .explore-button{
     width: 210px;
     height: 60px;
     background-color: #0056b3;
     font-size:14px;
  }
 }
 
 @keyframes fadeInSlide {
   0% {
     opacity: 0;
     transform: translateY(-50%);
  }
   100% {
     opacity: 1;
     transform: translateY(0);
  }
 }
 
 .animated-text {
  animation: fadeInSlide 2s ease-out forwards;
 }
 
 .company-name{
  font-size: 6rem;
  font-weight: bold;
 }
 
 .slogan{
  font-size: 1rem;
  position: relative;
  bottom: 10px;
 }
 
 /* Responsive adjustments */
 @media screen and (max-width: 1024px) {
  .company-name {
     font-size: 5rem;
  }
  .slogan {
     font-size: 1rem;
  }
  .button{
    position: absolute;
    top: 70%;
    left: 300px;
 }
 }
 
 @media screen and (max-width: 768px) {
  .company-name {
     font-size: 4rem;
  }
  .slogan {
     font-size: 1rem;
  }
  .button{
    position: absolute;
    top: 70%;
    left: 105px;
 }
 }
 
 @media screen and (max-width: 480px) {
  .company-name {
     font-size: 3.5rem;
  }
  .slogan {
     font-size: 0.8rem;
  }
  .button{
    position: absolute;
    top: 70%;
    left: 80px;
 }
 }
 