/* TestModal.css */
.modal {
    display: flex;
    justify-content: center; /* Horizontally center the modal */
    align-items: center; /* Vertically center the modal */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    animation: fadeIn 0.3s ease; /* Fade in animation */
}

.modal-content {

    background-color: white; /* Gradient background */
    width: 450px;
    height: 500px;
    max-width: 90%; /* Limit maximum width for smaller screens */
    max-height: 90%; /* Limit maximum height for smaller screens */
    overflow-y: auto; /* Add scrollbar if content exceeds modal height */
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Soft shadow effect */
    position: relative; /* Ensure absolute positioning works relative to this */
    animation: bounceIn 0.5s ease; /* Bounce in animation */
}


.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: black; /* Dark color for close button */
}

/* Additional styles for the content within the card */
.modal-button {
    background-color: black; /* Blue button */
    color: white; /* White text color */
    padding: 10px 20px; /* Add padding to the button */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px; /* Add some space between button and other content */
    transition: background-color 0.3s ease; /* Smooth transition for button color */
    animation: pulse 1.5s infinite alternate; /* Pulse animation */
}

.modal-button:hover {
    background-color: black;
    color: white; /* Darker shade of blue on hover */
}

.text {
    color: black; /* Dark text color */
    font-size: 1.5rem; /* Adjust font size */
    line-height: 1; /* Adjust line height */
    text-align: center;
}

/* Animation Keyframes */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes bounceIn {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}

@keyframes pulse {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}
