.screen {
    background-color: #4793AF;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
}

.icon-wrapper {
    color: #4793AF;
}
.bg-white {
    width: 100%;
    max-width: 360px; /* Set a max-width for larger screens */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background: #FFF;
}

@media (max-width: 768px) {
    .bg-white {
        padding: 1.5rem;
        max-width: 100%;
    }
}

/* Additional responsiveness for very small devices */
@media (max-width: 480px) {
    .bg-white {
        padding: 1rem;
    }
}
