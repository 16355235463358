.relative {
    position: relative;
  }
  
  .absolute {
    position: absolute;
  }
  
  .banner-container {
    display: inline-block;
    position: relative;
  }
  
  .banner{
    width: 100vw;
    height: 80vh;
    object-fit: cover;
  }
  
  .text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    width: 100%;
  }
  
.about-heading{
  margin-top: 30px;
  font-size: 2rem;
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.about{
  margin-bottom: 50px;
}
.about-name{
  font-size: 3rem;
  color: white;
  font-weight: bold;
}