.about-container {
    text-align: center;
    padding: 20px;
  }
  
  .college-image {
    width: 100%; /* Makes the image full width of its container */
    height: auto; /* Maintains the aspect ratio of the image */
    margin-top: 20px;
  }
  