/* Dashboard.css */
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999; 
}

.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 200px;
}

.main-content-container {
  flex: 1; 
  margin-left: 200px;
  overflow-y: auto; 
}


.main-content {
padding: 80px;
}
