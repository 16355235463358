/* Sidebar.css */
.sidebar {
  width: 250px;
  height: 150vh;
  background-color: darkslategrey;
  color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transition: width 0.3s ease;
}

.sidebar h2 {
  margin-bottom: 20px;
  color: #fff;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.sidebar ul li:hover {
  background-color: #555; /* Change to your desired hover color */
}

.sidebar ul li svg {
  margin-right: 10px;
}

.toggle-icon {
  cursor: pointer;
  margin-bottom: 10px;
}
.active-link {
  color: #f00; /* Active link color */
  font-weight: bold; /* Make it bold */
}


@media only screen and (min-width: 769px) {
  .sidebar {
    width: 100px;
    padding: 10px;
  }
  
  .sidebar h2 {
    display: none; /* Hide h2 heading */
  }
  
  .sidebar.open {
    width: 250px;
  }
  
  .sidebar.open h2,
  .sidebar.open ul {
    display: block; /* Show h2 heading and ul when sidebar is open */
  }
}
