.states-container {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 2rem;
  color: #333; /* Dark text color */
}

.card-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.state-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer; /* Add cursor pointer for better interactivity */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.state-card:hover {
  transform: scale(1.05);
}

.state-card h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  text-align: center; /* Center the state names */
  color: #555; /* State name color */
}

.more-info {
  margin-top: 20px;
  text-align: center;
  color: #777; /* Color for additional information */
}

.contact-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #004080; /* Darker shade of blue on hover */
}

/* Media Queries for Responsive Design */

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .state-card {
    width: 80%; /* Adjust width for smaller screens */
    margin-bottom: 20px; /* Add space between cards */
  }
}
