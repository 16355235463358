.footer {
    background-color: #4793AF; /* Change this to your desired footer background color */
    color: #fff;
    padding: 20px 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .footer p {
    margin: 0;
  }
  
  