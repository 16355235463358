.courseDropdownContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    border: 1px solid #ccc; 
    border-radius: 5px; 
    max-width: 800px;
  }
  
  .courseLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .courseLabel input[type="checkbox"] {
    margin-right: 10px; /* Space between checkbox and label text */
  }
  